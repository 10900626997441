export interface Introduction {
  paragraphs: string[];
}

const defaultIntroductionData: Introduction = {
  paragraphs: [
    "Software developer at a proprietary trading firm in New York specializing in signal measurement applications that produce fair values for traded products.",
    "Bachelor of Science in Engineering in computer science at Princeton University, with minors in Statistics and Machine Learning; Optimization and Quantitative Decision Science; and Applied and Computational Mathematics.",
    'Explored the feasibility of strategic manipulation in a model of a cryptocurrency with a proof-of-stake mining protocol as part of a senior thesis, see <a className="{classes.a}" href="https://thesis.anthonyhein.com">thesis.anthonyhein.com</a>.',
    'Earned the <a href="https://www.princeton.edu/news/2021/08/30/undergraduate-prizes-awarded-seven-students-academic-achievement#:~:text=Anthony%20Hein%20received%20the%20Class%20of%201939%20Princeton%20Scholar%20Award%2C%20which%20is%20awarded%20each%20year%20to%20the%20undergraduate%20who%2C%20at%20the%20end%20of%20junior%20year%2C%20has%20achieved%20the%20highest%20academic%20standing%20for%20all%20preceding%20college%20work%20at%20the%20University.">Class of 1939 Princeton Scholar Award</a> and <a href="https://www.cs.princeton.edu/news/congratulations-department-computer-science-class-2022#:~:text=Phillip%20Goldman%E2%80%9986,Anthony%20Hein">Philip Goldman \'86 Computer Science Prize</a>, some of the university\'s top honors for academic excellence.',
  ],
};

export default defaultIntroductionData;
